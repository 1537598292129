@import "plugins/sweetalert";
@import "plugins/slick-slider";

.react-tel-input .special-label {
  display: none;
}

.active .special-label {
  background-color: rgb(0, 0, 0, 0) !important;
  color: #00000061 !important;
  margin-left: 32px;
  display: block;
}

.disable .special-label {
  background-color: rgb(0, 0, 0, 0) !important;
  margin-left: 32px;
  display: block;
}

.flag-dropdown {
  height: 60px !important;
}


.scroll-hide::-webkit-scrollbar {
  display: none;
}
//
//.scroll-hide {
//  display: none;
//}

.scroll-hide {
  overflow: auto;
  -ms-overflow-style: none;
}

.call-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  animation: scaling 2s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite;
  top: 24px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.call-circle1 {
  animation-delay: 0s;
}

.call-circle2 {
  animation-delay: 1s;
}

.call-phone {
  animation: phone 1s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite alternate-reverse;
  position: relative;
  z-index: 2;

}

.react-tel-input .special-label {
  background-color: #fff !important;
  margin-left: -15px !important;
  display: block !important;
  color: #424242;
}

.border-gray fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #D1DAE5 !important;
}

.border-none fieldset.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

@keyframes scaling {
  0% {
    transform: scale(1);
    background-color: #fff;
  }
  100% {
    transform: scale(2.6);
    background-color: rgba(245, 0, 182, 0);
  }
}

@keyframes phone {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}


