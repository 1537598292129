#demo-simple-select {
    background-color: white;
}

.add-edit-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
    width: 100%;
    min-height: 590px;
    background-color: #fff;
    border: 0px solid #000;
    border-radius: 15px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1); /* CSS'deki shadow değeri 'box-shadow: 24' yerine gerçek bir gölge uygulaması */
    padding: 60px 20px 20px 20px;
    padding-bottom: 5px;
    overflow: auto;
}

.add-edit-modal::-webkit-scrollbar {
    width: 0px;
}
